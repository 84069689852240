 /* eslint-ignore-file */ 
import { defineStore } from 'pinia'

export const useStore = defineStore('landingPage', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      stepNumber: 1,
      formBtnLabel: '',
      stepNameLbl: '',
      reviewsText: null,
      selectedVideo: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.mp4?rand=17188158456666',
      reviewsCollection:
      {
        "head": {
          "status": true,
          "code": 200
        },
        "body": {
          "widgetlDetail": {
            "widgetName": "Reviews VDAI API",
            "widgetUrl": "636-1718298716",
            "widgetProfanity": "1",
            "widgetCreatedAt": "2024-06-13 22:41:56",
            "count": 6
          },
          "feeds": [
            {
              "feedName": "Vacation Deals All Inclusive",
              "networkName": "Facebook",
              "feedId": "202498",
              "postId": "5525032",
              "postContent": "If you are in search of a vacation deal look no further. I really recommend contacting Jessica Canales who is very knowledgeable and courteous. She can be contacted at 8776060014 ext 520. She was amazing and thanks to her I recently visited Villa del Palmar in Cabo. Food was delicious and room was clean and very comfortable.",
              "postScore": "pos",
              "image": "",
              "imageList": [],
              "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
              "postPin": "0",
              "postHighlight": "0",
              "postAuthorName": "Alejandra Zamora-Escobedo",
              "postUsername": "Alejandra Zamora-Escobedo",
              "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXER8QZjYuNgfVEzl7t-PunaQUeB6RQocEwUvlbLfCpHuHZIfFawx3WVQM5WVwZ9XhOatu_Lva0X&psid=25275771298705087&height=50&width=50&ext=1724974526&hash=Abb0fe6EGJWI_BiqfQHzCh4j",
              "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXEqq-9okHVnTrW0keS3Tr04fZQOIub2eeOiQRHgWAhcgvIpjDDMiGYLEcefImTBw3xIKgeRPoCB&psid=25275771298705087&height=50&width=50&ext=1720890742&hash=AbYZP6z4MYt4By0R49eJxrNL",
              "postCreatedAt": "1712348327",
              "postMediaFile": "",
              "rating": "5",
              "postUpdatedAt": "1718298742",
              "CTAtext": "",
              "CTAbackground": "",
              "CTAcolor": "",
              "CTAurl": "javascript:void(0);"
            },
            {
              "feedName": "Vacation Deals All Inclusive",
              "networkName": "Facebook",
              "feedId": "202498",
              "postId": "5525033",
              "postContent": "Adan my contact is very informed and handled all my needs very well. he is very accommodating.\nGave me good value for my money. Adan made my Cancun visit memorable!!\n\nI would recommend to speak with Adan before finalizing your vacation plans \n\nAdan Jesus Alvarez Vacation\n+52 322 157 8458",
              "postScore": "pos",
              "image": "",
              "imageList": [],
              "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
              "postPin": "0",
              "postHighlight": "0",
              "postAuthorName": "Anurag Khare",
              "postUsername": "Anurag Khare",
              "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGii9S_zsn_YC6N3q6Pxt3TM3Pdfemfbw-1LakqgOvMtjgOE_dc2_J95jaqwo8kaLrwbVd02PzW&psid=7322901294468198&height=50&width=50&ext=1724974526&hash=AbYB5Fs5QQfio3w5OT4IqpVE",
              "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXF-bka_zCUmHBGMPi_IuFVExpKDfugrnRzSkv9UHBLZeVbNkBoMgPgVku-pmwk6LWqyV1rBEVd4&psid=7322901294468198&height=50&width=50&ext=1720890742&hash=AbZUddJ5ynYzCBJXeW-mxKen",
              "postCreatedAt": "1711399741",
              "postMediaFile": "",
              "rating": "5",
              "postUpdatedAt": "1718298742",
              "CTAtext": "",
              "CTAbackground": "",
              "CTAcolor": "",
              "CTAurl": "javascript:void(0);"
            },
            {
              "feedName": "Vacation Deals All Inclusive",
              "networkName": "Facebook",
              "feedId": "202498",
              "postId": "5525034",
              "postContent": "Last year in August we visited Villa Del Palmar All inclusive hotel in Cancun, and it was such a wonderful experience. We have recommended it to family and friends because our experience was unforgettable. The variety of restaurants that are within the hotel and there\u2019s no need to leave at all. The food was delicious!! Loved that the buffet would change the menu everyday. The drinks were also so convenient to just stop by at any bar and order a drink with the all inclusive package. Even while out at the private beach, the gentlemen come over and take out orders. Such a wonderful experience we had and we are already planning to go again. My girls loved it as well as my husband. Very impressed with this hotel. Thanks a lot to Jessica Canales, she was so easy to talk to and would respond quickly to us when we had questions. I highly recommend Jessica at 877-606-0014 Ext 520, at Vacation Deals All inclusive. She made our family vacation so easy to book. She\u2019s wonderful. Thanks!! \ud83d\ude0a",
              "postScore": "pos",
              "image": "",
              "imageList": [],
              "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
              "postPin": "0",
              "postHighlight": "0",
              "postAuthorName": "Maritza Yvette",
              "postUsername": "Maritza Yvette",
              "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH9LvPAHpHdaIs1fmbREzRI6tz7C52q1JoerZRkuxc_OK1n3H-_4ERvnx_dEe88o2MsU2sG58It&psid=7747177741980499&height=50&width=50&ext=1724974526&hash=AbZKzSjj-vvMdaSMwAc7TENk",
              "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXE8bah6kwt-yE3wafeGbk9AWwZFaHKwa76ZMvyUENxemuuPIy3epuo9At692Sfh925o9_XzqHbY&psid=7747177741980499&height=50&width=50&ext=1720890742&hash=AbZtTtb3DRQ0g43f436w6knP",
              "postCreatedAt": "1710195799",
              "postMediaFile": "",
              "rating": "5",
              "postUpdatedAt": "1718298742",
              "CTAtext": "",
              "CTAbackground": "",
              "CTAcolor": "",
              "CTAurl": "javascript:void(0);"
            },
            {
              "feedName": "Vacation Deals All Inclusive",
              "networkName": "Facebook",
              "feedId": "202498",
              "postId": "5525035",
              "postContent": "Recently had the time of our lives at Villa del Palmar Cancun with Vacation Deals All Inclusive! This family-friendly resort is beyond beautiful, offering stunning views of the ocean and delicious food from multiple restaurant options. Our suite was not only beautiful but impeccably clean. The staff were courteous and made us feel at home. Can't recommend this experience enough \u2013 it's a must for anyone seeking a memorable getaway! My daughter and I tried kayaking for the first time and they offer other fun activities at the resort too. My kids loved every minute of our stay and refused to leave. It is truly paradise. I can\u2019t wait to return! \n\nThank you Jessica Canales for being amazing and answering all of our questions. Definitely recommend contacting her to book the vacation of your lifetime. Her contact number is 8776060014 ext 520.",
              "postScore": "pos",
              "image": "",
              "imageList": [],
              "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
              "postPin": "0",
              "postHighlight": "0",
              "postAuthorName": "Claudia Garcia",
              "postUsername": "Claudia Garcia",
              "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGQPv4dW2KGC9pW0LOHKQmFCETEXDU1yFeSZmSNLIMRyGCtPtHew-ytBzSIKChf1q3WG1CmB_7q&psid=7303565599724126&height=50&width=50&ext=1724974526&hash=AbZ-_QIw0F4TTnxrch0BdyJ9",
              "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXGIE_wLFi2NSQJgMy1uPSFe3xE2c9weMf9XrmfWCjv4vjVuGClCsLLGofKsoLxmsTUpBVSGxwz5&psid=7303565599724126&height=50&width=50&ext=1720890742&hash=AbZqzb3-HvKXURbaWXjtEbWD",
              "postCreatedAt": "1709864214",
              "postMediaFile": "",
              "rating": "5",
              "postUpdatedAt": "1718298742",
              "CTAtext": "",
              "CTAbackground": "",
              "CTAcolor": "",
              "CTAurl": "javascript:void(0);"
            },
            {
              "feedName": "Vacation Deals All Inclusive",
              "networkName": "Facebook",
              "feedId": "202498",
              "postId": "5525036",
              "postContent": "rating\nOur stay At Villa Del Palmar was Amazing! It\u2019s beautiful! The Drinks and food are delicious! The Hotel is very neat and Clean and all of the staff are super Friendly and attentive. I would definitely recommend Vacation Deals All Inclusive, so worth it! Our sales associate Jessica Canales was so helpful and answered all my questions and made sure we were taken care of. If you want to Visit Puerto Vallarta, Villa Del Palmar is the place to stay! You will enjoy every minute! If you want a great experience here is her contact information! Jessica Canales 8776060014 ext 520",
              "postScore": "pos",
              "image": "",
              "imageList": [],
              "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
              "postPin": "0",
              "postHighlight": "0",
              "postAuthorName": "Micah Cook",
              "postUsername": "Micah Cook",
              "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEephCE2_CvZ1l_XxlhOL1YcI-OdAgu1_5Vt3Im3sIBf9PAOp8cb3vGrzZuGWD6n9K8F9eywznd&psid=4233928146688098&height=50&width=50&ext=1724974526&hash=AbYlIFHxWIPOjqTy8EhQO7b",
              "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXEeUxe6OTUadruJbA_GkeNkVkd01WJefzWb4EobSlFTnuDrc2PSyoZ-rmsl0xBad_eTzanYeiYT&psid=4233928146688098&height=50&width=50&ext=1720890742&hash=Abb4nTtA5zrflUfQhFwmowOk",
              "postCreatedAt": "1709851295",
              "postMediaFile": "",
              "rating": "5",
              "postUpdatedAt": "1718298742",
              "CTAtext": "",
              "CTAbackground": "",
              "CTAcolor": "",
              "CTAurl": "javascript:void(0);"
            },
            {
              "feedName": "Vacation Deals All Inclusive",
              "networkName": "Facebook",
              "feedId": "202498",
              "postId": "5525037",
              "postContent": "Excellent service Great prices . Look no further!",
              "postScore": "pos",
              "image": "",
              "imageList": [],
              "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
              "postPin": "0",
              "postHighlight": "0",
              "postAuthorName": "Ej Bello",
              "postUsername": "Ej Bello",
              "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJfSNNVbAyRbBK4GmLkIYdFBIqcU0Oae_yMQTEYux1Ty609V5gdOTelI8tOMClwAwg770yEXEe&psid=4933842270055400&height=50&width=50&ext=1724974526&hash=Abah-sskPK4O643rN6vV1sox",
              "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXGSByrLb0H_M48VWP8Ef2-BC6Qfh42gA__LmoAZNDRZhQZOpgbVp0ikQIR6oPK08Nj1Y_LiO3tH&psid=4933842270055400&height=50&width=50&ext=1720890742&hash=AbboqpEXqv5ALW2zailVySST",
              "postCreatedAt": "1709679831",
              "postMediaFile": "",
              "rating": "5",
              "postUpdatedAt": "1718298742",
              "CTAtext": "",
              "CTAbackground": "",
              "CTAcolor": "",
              "CTAurl": "javascript:void(0);"
            }
          ]
        }
      },
      detailsGallery: [
        { imgUrl: require('@/assets/images/landing/gallery/image-01.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-01.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-02.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-02.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-03.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-03.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-04.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-04.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-05.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-05.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-06.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-06.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-07.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-07.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-01.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-01.png') },
        { imgUrl: require('@/assets/images/landing/gallery/image-02.jpeg'), coverUrl: require('@/assets/images/landing/gallery/image-cover-02.png') },
      ],
      reviewsVideoGallery: [
        {
          id: 1,
          posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.png?rand=17188158456666',
          videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.mp4?rand=17188158456666'
        },
        {
          id: 2,
          posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/18253139293223535.png?rand=17188158565275',
          videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/18253139293223535.mp4?rand=17188158565275'
        },
        {
          id: 3,
          posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/18012430991134767.png?rand=17188158692313',
          videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/18012430991134767.mp4?rand=17188158692313'
        },
        {
          id: 4,
          posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/18030286709047423.png?rand=17188158609535',
          videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/18030286709047423.mp4?rand=17188158609535'
        },
        {
          id: 5,
          posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/17918886371912347.png?rand=17188158655119',
          videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/17918886371912347.mp4?rand=17188158655119'
        },
        {
          id: 6,
          posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.png?rand=17188158456666',
          videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.mp4?rand=17188158456666'
        }
      ],
      travelDate: null
    }
  },
  getters: {
    getStepNumber() {
      return this.stepNumber
    },
    getPlusOneStepNumber() {
      return this.stepNumber + 1
    },
    getFormBtnLabel() {
      this.setFormBtnLabel()
      return this.formBtnLabel
    },
    getStepNameLbl() {
      this.setStepNameLbl()
      return this.stepNameLbl
    },
    getTextReviews(state) {
      this.setTextReviews()
      return state.reviewsText
    }
  },
  actions: {
    setPlusOneStepNumber(currentStep, form) {
      if (this.stepNumber < 5) {
        if (currentStep == 1) {
          if (document.getElementById("firstName").checkValidity() == false) {
            document.getElementById("firstName").classList.add("is-invalid")
            this.showValidationToast()
          } else {
            document.getElementById("firstName").classList.add("is-valid")
            document.getElementById("firstName").classList.remove("is-invalid")
          }
          if (document.getElementById("lastName").checkValidity() == false) {
            document.getElementById("lastName").classList.add("is-invalid")
            this.showValidationToast()
          } else {
            document.getElementById("lastName").classList.add("is-valid")
            document.getElementById("lastName").classList.remove("is-invalid")
          }
        }
        if (currentStep == 2) {
          if (document.getElementById("email").checkValidity() == false) {
            document.getElementById("email").classList.add("is-invalid")
            this.showValidationToast()
          } else {
            document.getElementById("email").classList.add("is-valid")
            document.getElementById("email").classList.remove("is-invalid")
          }
          if (document.getElementById("phoneNumber").checkValidity() == false) {
            document.getElementById("phoneNumber").classList.add("is-invalid")
            this.showValidationToast()
          } else {
            document.getElementById("phoneNumber").classList.add("is-valid")
            document.getElementById("phoneNumber").classList.remove("is-invalid")
          }
        }
        if (currentStep == 3) {
          if (this.travelDate == null || this.travelDate == 0) {
         
            this.travelDate = false
            this.showValidationToast()

          } else {
            this.travelDate = true
            document.getElementById("datePickerComponent").classList.add("is-valid")
            document.getElementById("datePickerComponent").classList.remove("is-invalid")
          }
        }
        if (currentStep == 4) {
          console.log(document.getElementById("termsAgreement").checked)
          if (document.getElementById("termsAgreement").checked) {
            document.getElementById("termsAgreement").classList.add("is-valid")
            document.getElementById("termsAgreement").classList.remove("is-invalid")
          } else {
            document.getElementById("termsAgreement").classList.add("is-invalid")
            this.showValidationToast()
          }
        }
        if (document.getElementById("firstName").checkValidity() && document.getElementById("lastName").checkValidity() && currentStep == 1) {
          this.stepNumber = 2
        }
        if (document.getElementById("email").checkValidity() && document.getElementById("phoneNumber").checkValidity() && currentStep == 2) {
          this.stepNumber = 3
        }
        if (this.travelDate == 1 && currentStep == 3) {
          this.stepNumber = 4
        }
        if (document.getElementById("termsAgreement").checked && currentStep == 4) {
      
        }
      }
    },
    setMinusOneStepNumber() {
      if (this.stepNumber > 1) {
        this.stepNumber--
      }
    },
    setFormBtnLabel() {
      switch (this.stepNumber) {
        case 1:
          this.formBtnLabel = 'I want this deal!'
          break;
        case 2:
          this.formBtnLabel = 'Almost got it!'
          break;
        case 3:
          this.formBtnLabel = 'Just one step away!'
          break;
          case 4:
            this.formBtnLabel = 'Submit'
            break;
        default:
          break;
      }
    },
    setStepNameLbl() {
      switch (this.stepNumber) {
        case 1:
          this.stepNameLbl = 'Personal Information'
          break;
        case 2:
          this.stepNameLbl = ' Contact Information'
          break;
          case 3:
            this.stepNameLbl = 'Travel Date'
            break;
        case 4:
          this.stepNameLbl = 'Time to be reached'
          break;
        default:
          break;
      }
    },
    setTextReviews() {
      var obj;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "2b8d64ff0609b8a8913c62bb9b144293");
      var formdata = new FormData();
      formdata.append("widgetId", "152844");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch("https://api.tagembed.com/app/api/", requestOptions)
        .then(response => response.text())
        .then(result => obj = JSON.parse(result))
        .then(() => {
          this.reviewsText = obj
        })
        .catch(error => console.log('error', error));
      //   axios.post('https://api.tagembed.com/app/api/', {
      //     params: {
      //       'widgetId': '152844'
      //     },
      //     headers: {
      //     'Authorization': '2b8d64ff0609b8a8913c62bb9b144293',
      //   'Access-Control-Allow-Headers': 'Authorization',
      //   'Access-Control-Allow-Methods':  'POST',
      // }
      //   })
      //     .then(function (response) {
      //       console.log('SUCCES');
      //       console.log(response);
      //     })
      //     .catch(function (error) {
      //       console.log('FAIL');
      //       console.log(error);
      //     })
      //     .finally(function () {
      //       // always executed
      //     });

    },
    showValidationToast() {
      const toastLiveExample = document.getElementById('liveToast')
      //eslint-disable-next-line
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
      toastBootstrap.show()
  }
  }
})
