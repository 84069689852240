<template>
  <div class="container-fluid main-container m-0 p-0 full">
    <div class="container-fluid bg h-100">
      <div class="row h-100">
        <div class="col-12">
          <div class="row">
            <div class="col text-center p-3">
              <img src="../../assets/images/landing/logo.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-lg-center align-items-end pt-5 mt-5 pt-lg-0 mt-lg-0">
          <div class="container pt-0 mt-5 pt-lg-0 mt-lg-0">
            <div class="row d-flex align-content-center h-100">
              <div class="col-12 col-lg-6 d-lg-grid justify-content-center align-items-center align-self-center">
                <div class="col col-lg-10">
                  <h1 class="lbl-01__title">Riviera-Cancun <br> Luxury Vacation</h1>
                  <h3> <span class="lbl-01__sub px-2">$499 USD per 2 adults</span></h3>
                  <p class="lbl-01__p">
                    5-Days & 4-Nights Couple's vacation at a luxury resort.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-6 d-flex justify-content-center ">
                <div class="col col-lg-7 mb-2 pb-1">
                  <TheForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-end">
          <div class="row">
            <div class="col text-center"><img src="../../assets/images/landing/details-section/arrow-down.gif" alt=""
                class="rounded-top-5 p-1 arrow-icon"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <the-details />
  <the-amenities />
  <the-reviews />
</template>

<script>
import TheForm from "@/components/landing-page/TheForm.vue";
import TheDetails from "@/components/landing-page/TheDetails.vue"
import TheAmenities from "@/components/landing-page/TheAmenities.vue"
import TheReviews from "@/components/landing-page/TheReviews.vue"
import { ref } from 'vue'

export default {
  components:
  {
    TheForm,
    TheDetails,
    TheAmenities,
    TheReviews
  },
  mounted() {
    // // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = window.innerHeight * 0.01;
    // // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
    // // We listen to the resize event
    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   let vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });
  }
}


</script>

<style scoped>
.main-container {
  background-image: url(/src/assets/images/landing/bg-01.jpeg);
  background-size: cover;
  background-position-x: center;
  /* height: 100vh !important; */
  /* Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100) !important; */
}

.full {
  height: 100vh !important;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
}

.lbl-01__title {
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 111.111% */
}

.lbl-01__sub {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #0C9491;
  color: #FFF;
  font-size: 24px;
}

.lbl-01__p {
  font-family: "Poppins", sans-serif;
  color: #FFF;
}

.bg {
  background-color: rgba(0, 0, 0, .4) !important;
  /* height: 100vh !important; */
  /* Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100) !important; */
}

.my-element {
  /* height: 100vh; */
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.margin-15 {
  margin: 0%;
  margin-top: 10rem;
}

.arrow-icon {

  /* margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
bottom: 0px; */
  background-color: #FFF;
  width: 35px;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

@media (min-width: 992px) {
  .lbl-01__title {
    padding-top: 0%;
    margin-top: 0%;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 111.111% */
  }


  .p-lg-12 {
    padding: 0rem 9%;
  }

  .margin-15 {
    margin: 11%;
  }
}


</style>