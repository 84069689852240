<template>
  <div>
    <div class="container-fluid p-0 " :style="dynamicBgImg">
      <div class="row  px-4 back-black">
        <div class="col-12 col-lg-4">
        </div>
        <div class="col-12 col-lg-4 py-lg-5">
          <div class="d-flex justify-content-center pb-3 pt-5">
            <h2 :style="getTitleStyle">
                  <b>{{ destinationDetails.name }}</b>
                </h2>
          </div>
          <div class="d-flex pb-5">
            <div class="col-10">
              <select class="form-select form-control input" v-model="selected">
                <option v-for="destination in destinations.slice(0, 6)" :key="destination.id" :value="destination.id"
                  :hidden="destination.hidden">
                  {{ destination.name }}
                </option>
              </select>
            </div>
            <div class="col-2 px-xxl-3 px-1">
              <router-link :to="toRoute" custom v-slot="{ navigate }">
                  <button type="submit" class="btn submit material-icons-outlined" style="color: white" @click="navigate">
                    arrow_forward
                  </button>
                </router-link>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center" v-if="websiteStore.getIsCampaign">
          <img :src="websiteStore.campaign.iconBanner.imgUrl" alt="" class="h-75">
        </div>
      </div>
    </div>
    <div class="container-fluid"  :style="websiteStore.campaign.bgCampaign" v-if="websiteStore.getIsCampaign">
      <div class="row p-3 p-lg-0 d-flex justify-content-center"  :style="websiteStore.campaign.bgCampaign" >
        <div class="col-12 col-lg text-lg-end text-center align-content-center">
          <span class="campaign-font text-center">{{ websiteStore.campaign.lbl01 }}</span>
        </div>
        <div class="col-12 col-lg-1 text-center">
          <img :src="websiteStore.campaign.babyAsset" alt="" class="">
        </div>
        <div class="col-12 col-lg-4 align-content-center text-lg-start text-center">
          <a href="tel:+18776923883" class="text-decoration-none"><span class="campaign-font-02 fs-4">{{ websiteStore.campaign.lbl03 }}</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const getImagePath = (image = "") => {
  return require(`@/assets/images/filter_banner_imgs/${image}`)
}

import { useWebsiteStore } from '@/store/website';

export default {
  setup() {
    const websiteStore = useWebsiteStore();
    return {
      websiteStore
    }
  },
  props: {
    destinationDetails: {
      type: Object,
      default: function () {
        return {
          image: getImagePath("cover.webp"),
          name: "Look for the Best Vacation Deal",
          isHome: true
        }
      }
    },
    slug: String
  },
  watch: {
    slug(value) {
      this.checkSlug(value);
    }
  },
  data() {
    return {
      selected: 0,
      dynamicBgImg: '',
      titleStyle: ''
    };
  },
  computed: {
    dynamicBg() {
      return this.dynamicBgImg
    },
    destinations() {
      let destinations = [...this.websiteStore.destinations];

      destinations.forEach(x => {
        x.componentName = 'destination';
      });

      destinations.unshift({
        id: 0,
        name: '',
        componentName: 'home',
        hidden: true,
      });

      return destinations;
    },
    toRoute() {
      let destination = this.destinations.find(x => x.id == this.selected);

      let toRoute = { name: destination.componentName };

      if (destination.slug) {
        toRoute.params = {
          slug: destination.slug
        };
      }

      return toRoute;
    },
    getTitleStyle() {
      this.setTitleColor()
      return this.titleStyle
    }
  },
  methods: {
    checkSlug(slug) {
      if (slug) {
        this.selected = this.destinations.find(x => x.slug == slug).id;
      } else {
        this.selected = 0;
      }
    },
    setTitleColor() {
      if (this.websiteStore.getIsCampaign) {
        this.titleStyle = this.websiteStore.campaign.titleColor
      }
    }
  },
  mounted() {
    this.checkSlug(this.slug);
    if (this.websiteStore.getIsCampaign) {
      this.dynamicBgImg = this.dynamicBgImg = `background-image: url(${this.websiteStore.campaign.bgBanner.backgrImage});background-size: cover;background-position: center !important;`
    } else {
      this.dynamicBgImg = `background-image: url(${this.destinationDetails.image});background-size: cover;background-position: center !important;`;
    }
  }
};
</script>

<style scoped>
.submit {
  background: var(---0c9491) 0% 0% no-repeat padding-box;
  background: #0c9491 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 48px;
  width: 100%;
}

.submit:hover {
  background-color: #0A6B69;
}

.back-black {
  background-color: rgba(0, 0, 0, 0.5);
}

.normal-back {
  background-color: transparent;
}

.title-banner {
  color: #ffff;
}

.card-title {
  color: #0c9491;
  font-weight: bold;
  font-size: 24px;
}

.card-text {
  font-size: 16px;
}

.btn-package {
  background: transparent linear-gradient(90deg, var(---0c9491) 0%, var(---3a2050) 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, #0c9491 0%, #3a2050 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffff;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  font-size: 12px;
}

.card-img-overlay {
  color: #ffff;
}

.place {
  font-size: 14px;
}

.retail-price {
  display: block;
  text-align: left;
  text-decoration: line-through;
  font: normal normal normal 12px/24px Poppins;
}

.price {
  text-align: left;
  font: normal normal bold 24px/24px Poppins;
}

.per {
  text-align: left;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
}

.card-body {
  background-color: #fff;
}

.span {
  display: block;
}

.material-icons-outlined {
  line-height: none !important;
}

.input {
  border: 2px solid #0c9491;
  height: 48px;
}

h2 {
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: bold;
  color: #ffff;
}

.campaign-font {
  font-family: Poppins !important;
  ;
  font-weight: 900;
  color: #fff;
}

.campaign-font-02 {
  font-family: Poppins !important;
  ;
  font-weight: 900;
  color: #fff;
}

.title-campaign {
  color: #ffffff;
}

.baby-asset {
  width: 7%;
}

.brandig-mobile {
  justify-items: center;
}

@media (min-width: 992px) {
  .campaign-lbl-section {
    border-top: #025d20 2px solid;
  }
}
</style>