<template>
  <div class="modal" tabindex="-1" id="modalBannerLottery">

    <transition name="fadeOut">
      <div class="modal-dialog" v-if="isSelected == 1">
        <div class="modal-content">
          <button type="button" class="btn-close position-absolute end-0 p-3 btn-close-white" data-bs-theme="dark"
            data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-body modal__bg--color text-white p-0">
            <div class="container-fluid modal__bg--img p-0">
              <div class="row text-center gy-3">
                <div class="col-12 pt-4">
                  <img src="../assets/images/campaigns/nye-2024/lbl-01.png" alt="" class="lbl-01-img">
                </div>
                <div class="col-12">
                  <h3 class="lbl-02">Click here to get your 2025 <br> VACATION BONUS</h3>
                </div>
                <div class="col-12" @click="play">
                  <img src="../assets/images/campaigns/nye-2024/bowl.png" alt="" class="call-to-action__img">
                  <img src="../assets/images/campaigns/nye-2024/cursor.png" alt=""
                    class="position-absolute start-0 cursor">
                </div>
                <div class="col-12">
                  <span class="lbl-03 px-3">
                    PICK A GRAPE!
                  </span> <br>
                  <h3 class="lbl-02">and make a wish</h3>
                </div>
                <div class="col-12 d-flex">
                  <div class="col-lg-6 col-8 terms-lbl text-start align-self-center p-3">
                    <span>
                      <span>Does not apply with other promotions.</span> <br>
                      <span>Valid with the purchase of a</span> <br>
                      <span>Vacation Package to Mexico.</span> <br>
                      <span>*Only with the purchase of a $599 package.</span> <br>
                    </span>
                  </div>
                  <div class="col-lg-6 col-4 d-flex justify-content-end">
                    <img src="../assets/images/campaigns/nye-2024/logo.png" alt="" class="logo-lottery">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fadeIn">
      <div class="modal-dialog" v-if="isSelected == 2">
        <div class="modal-content">
          <div class="modal-header p-0">
            <div class="col-12 d-flex justify-content-center modal-title__prize p-3">HAPPY NEW YEAR</div>
            <button type="button" class="btn-close position-absolute end-0 p-lg-4 pe-3" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body modal__bg--color p-0">
            <div class="container-fluid modal-prize__bg--img">
              <div class="row text-center gy-3">
                <div class="col-12">
                  <span class="modal-prize__lbl-03 px-3">
                    YOU'VE WON
                  </span> <br>
                </div>
                <div class="col-12">
                  <img :src="prize.imgUrl" alt="" class="w-50">
                </div>
                <div class="col-12 text-white text-uppercase lh-1 fw-bolder pb-4 lbl-condition">
                  *Valid with a purchase of a vacation package to Mexico
                </div>
                <div class="col-12">
                  <TheLotteryForm :prizeCode="prize.code" :prizeName="prize.name" />
                </div>
                <div class="col-12  pb-3">
                  <span class="modal-prize__lbl-03">
                    To Redeem Your Prize!
                  </span> <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
  <div class="position-fixed bottom-0 end-0 w-100 validation-toast">
    <div class="d-lg-flex justify-content-end">
      <div id="liveToast" class="toast text-bg-danger w-100" role="alert" aria-live="assertive" aria-atomic="true"
        data-bs-delay="3000">
        <div class="toast-header">
          <strong class="me-auto pe-3">Please provide this information</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Confetti from 'canvas-confetti'
import TheLotteryForm from './TheLotteryForm.vue';

export default {
  data() {
    return {
      isSelected: 1,
      prize: {
        name: '',
        code: '',
        imgUrl: ''
      }
    }
  },
  components: {
    TheLotteryForm
  },
  methods: {
    showModal() {
      let bannerModal = document.querySelector('#modalBannerLottery', { backdrop: false });
      console.log(bannerModal)
      //eslint-disable-next-line
      const bannerModalInstance = new bootstrap.Modal(bannerModal);
      bannerModalInstance.show();
    },
    getConfetti() {
      Confetti({
        particleCount: 500,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 2000,
        shapes: ['square', 'star'],
        colors: ['#43276f', '#782156', '#e2d2a8'],
        startVelocity: 20,
      })
    },
    play() {
      this.getConfetti()
      setTimeout(() => {
        this.isSelected = 2
      }, 500);
    },
    setRandomNumber(max) {
      return Math.floor(Math.random() * max);
    },
    setPrize() {
      switch (this.setRandomNumber(4)) {
        case 0:
          this.prize.name = 'FREE CRUISE',
            this.prize.code = '#VALCRUISE',
            this.prize.imgUrl = require('@/assets/images/campaigns/nye-2024/prizes/prize-01.png')
          break;
        case 1:
          this.prize.name = '$100 USD DISCOUNT',
            this.prize.code = '#B100DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/nye-2024/prizes/prize-02.png')
          break;
        case 2:
          this.prize.name = 'FREE VACATION LAS VEGAS',
            this.prize.code = '#VALVEGAS',
            this.prize.imgUrl = require('@/assets/images/campaigns/nye-2024/prizes/prize-03.png')
          break;
        case 3:
          this.prize.name = '$75 usd DISCOUNT',
            this.prize.code = '#VAL75DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/nye-2024/prizes/prize-04.png')
          break;
        case 4:
          this.prize.name = '$50 usd DISCOUNT',
            this.prize.code = '#VAL50DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/nye-2024/prizes/prize-05.png')
          break;
        // case 2:
        //     this.prize.name = '2X1 GOLF ROUND',
        //         this.prize.code = '#B2X1GOLF',
        //         this.prize.imgUrl = 'images/lottery/back-to-school/prizes/prize-03.png'
        //     break;
        default:
          break;
      }
    }
  },
  mounted() {
    // this.setPrize()
    // this.showModal()
    // this.getConfetti()

  }
}
</script>

<style scoped>
.modal__bg--color {
  background-color: black !important;
}

.modal__bg--img {
  background-image: url(../assets/images/campaigns/nye-2024/bg-ornaments.png);
  background-size: contain;
}

.lbl-02 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  position: relative;
  z-index: 3 !important;
  color: #f7e9cb;
}

.lbl-03 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  position: relative;
  z-index: 3 !important;
  color: #1d1d1b;
  background-color: #f7e9cc;
  font-size: 2rem;
}

.terms-lbl {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  position: relative;
  z-index: 3 !important;
  color: white;
  font-size: .5rem;
}

.logo-lottery {
width: 50%;
}

.call-to-action__img {
  width: 60%;
  transition: filter 0.3s ease;
}

.call-to-action__img:hover {
  filter: drop-shadow(0 0 0.75rem #f7e9cb);
}

.cursor {
  width: 10%;
  bottom: 254px;
  left: 14% !important;
}

.modal-title__prize {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 2rem;
  color: #1d262d !important;
  background-color: #e9ce77;
}

.modal-prize__bg--img {
  background-image: url(../assets/images/campaigns/nye-2024/bg-prize.jpg);
  background-size: cover;
}

.modal-prize__lbl-03 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  position: relative;
  z-index: 3 !important;
  color: white;
  font-size: 2rem;
}

.lbl-condition {
  font-size: .8rem;
}

.btn-close {
  background-color: transparent !important;
}

.validation-toast {
  z-index: 2000 !important;
}

#liveToast .toast-header {
  background-color: var(--bs-form-invalid-border-color);
  background-clip: no-clip;
  border: 15px !important;
  border-radius: 0px !important;
  color: white;
}

.lbl-01-img {
  width: 35%;
}


@media (min-width: 992px) { 
  .logo-lottery {
width: 30%;
}
 }

 .swing--initial-state {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: center;
}
/*-- TRANSITIONS --*/
.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 1.5s ease-out;
}

.fadeIn-enter-from,
.fadeIn-leave-to {
  opacity: 0;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(-3deg);
  }
}
</style>