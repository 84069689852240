<template>
  <!-- Button trigger modal -->
  <!--<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalBanner">
      Launch demo modal
  </button>-->
  <div>
    <!-- Modal Wheel -->
    <div class="modal fade z-3" id="modalBanner" tabindex="-1" aria-labelledby="modalBannerLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered z-3">
        <div class="modal-content bg-color">
          <div class="text-end">
            <button type="button" class="btn-close m-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close" id="closeModal"> <i class="bi bi-0-circle"></i></button>
          </div>
          <div class="modal-body p-0 text-center bg-image" style="--bs-bg-opacity: .5;">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-12 pt-3">
                  <span class="wheel-title">Spin to Win Big for</span>
                  <img src="../assets/images/campaigns/kick-off-2025/2025.png" alt="" class="w-50 pt-3">
                </div>
                <div class="col-12">
                  <img src="../assets/images/campaigns/kick-off-2025/spiner.png" alt="" class="img-spinner">
                  <div class="col-12">
                    <canvas id="canvas" width="345" height="320" class="text-center">
                                    <!--height="448"-->
                                    <p style="
                           {
                            color: white;
                          }
                        " align="center">
                    Sorry, your browser doesn't support canvas. Please try another.
                                    </p>
                                  </canvas>
                  </div>
       
            <!-- <img id="spin_button" src="@/assets/images/winwheel/spin-btn.png" class="w-50 pb-3 pt-1 btn" alt="Spin"
                                @click="startSpin()" /> -->
            <div class="border border-none rounded-pill btn px-5 py-0 btn-spin mb-3" @click="startSpin()">SPIN</div>
                </div>
                <div class="col-12 d-flex">
                  <div class="col-9 text-start lbl-condition align-content-center ps-3">
                    Does not apply with other promotions. <br>
                    Valid with the purchase of a <br>
                    vacation Package to Mexico. <br>
                    *Only with the purchase of a $599 package.
                  </div>
                  <div class="col-3 text-end">
                    <img src="../assets/images/campaigns/kick-off-2025/logo.png" alt="" class="w-75">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Wheel -->
    <div class="modal fade " id="modalPrize" tabindex="-1" aria-labelledby="modalPrize" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content backgr-prize">
          <div class="text-end">
            <button type="button" class="btn-close m-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0 text-center backgr-prize" style="--bs-bg-opacity: .5;">
            <div class="col-12 prize__bg--color pt-md-4 pb-md-3">
              <h1 class="fw-bolder text-white text-uppercase">congrats!</h1>
            </div>
            <img :src="prizeImageUrl" alt="" class="w-50 pt-md-4 pt-2 pb-md-3 pb-2">
            <h6>*Valid with the purchase of a Vacation Package to Mexico.</h6>
            <lotteryForm :prizeCode="this.prizeLbl" :prizeName="prizeName" />
            <h3 class="text-white fw-bold">TO REDEEM YOUR KICKOFF BONUS</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useWebsiteStore } from '@/store/website';
import * as wheel from "@/Winwheel.js";
import wheelBack from "@/assets/images/campaigns/kick-off-2025/wheel.png"
import lotteryForm from "@/components/TheLotteryForm.vue"
import Confetti from 'canvas-confetti'

export default {
  setup() {
    const websiteStore = useWebsiteStore();

    return {
      websiteStore,
    }
  },
  data() {
    return {
      banner: this.websiteStore.banners,
      now: new Date(),
      modal: null,
      prizeModal: null,
      theWheel: null,
      loadedImg: '',
      wheelSpinning: false,
      wheelPower: 0,
      prizeLbl: null,
      prizeImageUrl: null,
      prizeName: "",
      back: wheelBack,
      hasPlayed: false
    }
  },
  components:
  {
    lotteryForm
  },
  methods: {
    showModal() {
      let bannerModal = document.querySelector('#modalBanner');
      //eslint-disable-next-line
      const bannerModalInstance = new bootstrap.Modal(bannerModal);
      bannerModalInstance.show();
    },
    closeModal() {
      //eslint-disable-next-line
      var pagebutton = document.getElementById("closeModal");
      pagebutton.click();
    },
    showPrizeModal() {
      let bannerModal = document.querySelector('#modalPrize');
      console.log(bannerModal)
      //eslint-disable-next-line
      const bannerModalInstance = new bootstrap.Modal(bannerModal);

      Confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 2000,
        shapes: ['triangle'],
        colors: ['#cf0103', '#3a7afe', '#fffff']
      })

      bannerModalInstance.show();
    },
    newWheel() {
      // Create new wheel object specifying the parameters at creation time.

      this.theWheel = new wheel.Winwheel({
        'canvasId': 'canvas',
        'numSegments': 6,            // Specify number of segments.
        'outerRadius': 150,          // Set outer radius so wheel fits inside the background.
        'drawMode': 'image',      // drawMode must be set to image.
        'drawText': false,         // Need to set this true if want code-drawn text on image wheels.
        'textFontSize': 12,
        'textOrientation': 'curved',     // Note use of curved text.
        'textDirection': 'reversed',   // Set other text options as desired.
        'textAlignment': 'outer',
        'textMargin': 5,
        'textFontFamily': 'monospace',
        'textStrokeStyle': 'black',
        'textLineWidth': 2,
        'textFillStyle': 'white',
        'segments':                // Define segments.
          [
            { 'text': '100USD' },
            { 'text': '50USD' },
            { 'text': '75USD' },
            { 'text': '50USD' },
            { 'text': 'FREEVEGAS' },
            { 'text': 'FREECRUISE' }
          ],
        'animation':                   // Specify the animation to use.
        {
          'type': 'spinToStop',
          'duration': 5,             // Duration in seconds.
          'spins': 8,             // Number of complete spins.
          'callbackFinished': this.alertPrize
        },
        'animationShow':                   // Specify the animation to use.
        {
          'type': 'spinToStop',
          'duration': 5,             // Duration in seconds.
          'spins': 8,             // Number of complete spins.
          'callbackFinished': null
        },
        'wheelImage': this.back
      });

    },
    startSpin() {
      // Ensure that spinning can't be clicked again while already running.
      if (this.wheelSpinning == false) {
        // Based on the power level selected adjust the number of spins for the wheel, the more times is has
        // to rotate with the duration of the animation the quicker the wheel spins.
        if (this.wheelPower == 1) {
          this.theWheel.animation.spins = 2;
        } else if (this.wheelPower == 2) {
          this.theWheel.animation.spins = 5;
        } else if (this.wheelPower == 3) {
          this.theWheel.animation.spins = 8;
        }

        // Disable the spin button so can't click again while wheel is spinning.
        // document.getElementById('spin_button').src       = "spin_off.png";
        // document.getElementById('spin_button').className = "";

        // Begin the spin animation by calling startAnimation on the wheel object.
        this.theWheel.startAnimation();

        // Set to true so that power can't be changed and spin button re-enabled during
        // the current animation. The user will have to reset before spinning again.
        this.wheelSpinning = true;
        this.hasPlayed = true;
        // this.alertPrize() 
      }
    },
    alertPrize(indicatedSegment) {
      // Do basic alert of the segment text. You would probably want to do something more interesting with this information.
      // alert("The wheel stopped on " + indicatedSegment.text);
      switch (indicatedSegment.text) {
        case "100USD":
          this.prizeLbl = "#VAL1OODIS";
          this.prizeImageUrl = require('@/assets/images/campaigns/kick-off-2025/prizes/prize-01.png')
          break;
        case "50USD":
          this.prizeLbl = "#VAL50DIS";
          this.prizeImageUrl = require('@/assets/images/campaigns/kick-off-2025/prizes/prize-02.png')
          break;
        case "75USD":
          this.prizeLbl = "#VAL75DIS";
          this.prizeImageUrl = require('@/assets/images/campaigns/kick-off-2025/prizes/prize-03.png')
          break;
        case "FREEVEGAS":
          this.prizeLbl = "#VALVEGAS";
          this.prizeImageUrl = require('@/assets/images/campaigns/kick-off-2025/prizes/prize-04.png')
          break;
        case "FREECRUISE":
          this.prizeLbl = "#VALCRUISE";
          this.prizeImageUrl = require('@/assets/images/campaigns/kick-off-2025/prizes/prize-05.png')
          break;
        default:
          console.log(`Sorry, we are out of`);
      }
      if (this.hasPlayed) {
        this.prizeName = indicatedSegment.text
        this.showPrizeModal();
        this.closeModal();

      }
    },
    modalPrizeSelected() {
      const prizeModal = document.querySelector(this.prize);
      // eslint-disable-next-line
      this.prizeModal = new bootstrap.Modal(prizeModal);
      this.prizeModal.handleUpdate()
      this.prizeModal.show();
    },
  },
  watch: {
    loadedImg(value) {
      this.theWheel.wheelImage = value; // Make wheelImage equal the loaded image object.
      this.theWheel.draw();             // Also call draw function to render the wheel.
    }
  },
  created() {

  },
  mounted() {
    // this.alertPrize("100USD");
    // this.showPrizeModal();


    // this.newWheel();
    // this.loadedImg = new Image()
    // this.loadedImg.src = this.back;
    // this.theWheel.startAnimationShow();
    // this.showModal()

    if (this.banner[0].fromDate < this.now && this.banner[0].toDate > this.now) {

    }
  }
}

</script>

<style scoped>
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 1 !important;
  z-index: 1 !important;
  background-color: white !important;
}

.wheel-title {
  font-weight: bold;
  color: #ffff;
  font-size: 2rem;
}

.lbl-condition {
  font-size: .6rem;
  color: #fff;
  font-weight: bold;
}

.backgr-prize {
  background-image: url(../assets/images/campaigns/kick-off-2025/bg-image.jpg);  
background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.backgr::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.50);
}

.pointer {
  left: 221px;
  top: 231px;
  width: 55px;
}

.prize__bg--color {
  background-color: #018C8A;

  color: white;
}

.code {
  font-family: Bebas Neue;
  color: white;
}

h6 {
  font-family: montserrat;
  color: white;
  font-size: 10px;
}

.bg-color {
  background-color: #1d1d1b;
}

.bg-image {
background-image: url(../assets/images/campaigns/kick-off-2025/bg-image.jpg);  
background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

h1 {
  font-size: 45px !important;
}

.btn-spin {
  background-color: #fff;
  font-family: Bebas Neue;
  color: #DC256C;
  border-color: #fff !important;
  font-size: 40px;
  box-shadow: 2px 11px 5px 6px rgba(225,	49,	121,	1); 
  -webkit-box-shadow: 1px 10px 0px -1px rgba(225,	49,	121,	1);
  -moz-box-shadow: 2px 11px 5px 6px rgba(225,	49,	121,	1);
  position: relative;
  margin-top: -25px;
}

.img-spinner{
  width: 41px;
    position: relative;
    margin-bottom: -98px;
}

.fz-terms {
  font-size: 10px;
  color: #0039b7;
  padding-right: 11%;
}

.logo {
  width: 82px;
  bottom: -1px;
  right: 0px;
  margin: 0px;
}

.reddit-sans {
  font-family: "Reddit Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  color: #0039b7;
}

.alfa-slab-one-regular {
  font-family: "Alfa Slab One", serif;
  font-weight: 400;
  font-style: normal;
  color: #0039b7;
}

.main-title {
  padding-top: 4rem;
}

@media only screen and (max-width: 600px) {
  .pointer {
    left: 158px;
    top: 214px;
  }

  .main-title {
    padding-top: 3rem;
  }

}
</style>
